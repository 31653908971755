<!--提交点播页面-->
<template>
  <div style="overflow-x: hidden">
    <div v-if="false" class="bg"></div>
    <div class="container">
      <VanSkeleton
        :loading="loading"
        avatar
        avatar-shape="square"
        avatar-size="90"
        row="3"
        row-width="230"
        title
      >
      </VanSkeleton>
      <div v-if="!loading" class="Card">
        <div class="Card-image">
          <img :src="filmImg" alt="" />
        </div>
        <div class="Card-content">
          <h3 class="Card-title">{{ filmTitle }}</h3>
          <div class="Card-score">
            <van-rate
              v-model="filmScore"
              :size="14"
              allow-half
              color="#ffb242"
              readonly
            />
            <span>{{ filmPlayNum }} </span>人点播
          </div>
          <div class="Card-length">
            <span>{{ filmType }}</span>
            <span>{{ filmLength }}分钟</span>
          </div>
        </div>
      </div>
      <div class="playDate box">
        <p class="required">期望放映日期</p>
        <div class="timeDiv">
          <input
            id=""
            v-model="date"
            name=""
            placeholder="点击这里选择时间"
            readonly
            type="text"
            @click="show = true"
          />
          <div>
            <van-icon
              name="calendar-o"
            />
          </div>
        </div>
      </div>
      <div class="playAddress box" v-if="userPoint">
        <p class="required">期望放映地点</p>
        <div class="addressDiv">
          <!-- <input
            id=""
            v-model="address"
            name=""
            placeholder="点击这里填写放映地点"
            readonly
            type="text"
            @click="goToSelectAddress"
          />
          <div>
            <van-icon name="location-o" color="#686B73" />
          </div> -->
          <!-- {{ userPoint }} -->
          <van-cell :title="userPoint.name" @click="changePlaceTip" center is-link :label="userPoint.address">
            <template #right-icon>
              <van-icon name="location-o" color="#686B73" />
            </template>
          </van-cell>
        </div>
      </div>
      <!-- 联系方式 -->

      <div class="playMobile box">
        <p>联系方式（选填）</p>
        <div class="mobileDiv">
          <input type="text" v-model="mobile" placeholder="请输入手机号"   />
          <div>
            <van-icon name="close" @click="mobile = ''" />
          </div>
        </div>
      </div>
      <!-- {{ subParam }} -->
      <div v-if="$store.state.userType === '2'" class="playAddress box">
        <div>
          <van-field
            v-model="subParam.remark"
            autosize
            label="留言"
            maxlength="200"
            placeholder="请输入留言"
            rows="2"
            show-word-limit
            type="textarea"
          />
        </div>
      </div>
      <div class="playAddress box" style="margin-bottom: 80px">
        <p class="required">点播说明</p>
        <div class="desc-box">
          <div>
            1、点播提交后，当地公益放映放映公司将收到您的点播需求。公益放映放映公司将综合各村点播结果和实际情况，进行放映安排。
          </div>
          <div>
            2、点播提交成功将自动发起一场拼团，您可邀请其他用户一起点播，每人每场只可以一起点播一次。拼团人数越多的场次，将优先安排进行放映。
          </div>
          <div>
            3、具体放映安排以放映公告为准，如有疑问，请咨询当地公益放映放映公司。
          </div>
        </div>
      </div>
      <!--      {{subParam}}-->
      <div class="box-button">
        <van-button
          color="#3d8bff"
          round
          size="large"
          style="height: 40px; width: 80%"
          type="info"
          @click="submit"
          >提交</van-button
        >
      </div>
    </div>
    <weekPicker
      :min-date="minDate"
      :show.sync="show"
      @close="show = !show"
      @onConfirm="onConfirm"
    ></weekPicker>
    <area-select
      :show="addressShow"
      @close="closeArea"
      @onFinish="onFinish"
    ></area-select>
  </div>
</template>

<script>
import { SetPvAndUv } from '@/mixins/PvAndUv'
import { Dialog, Toast } from 'vant'
import areaSelect from '@/components/areaPicker/areaSelect'
import weekPicker from '@/components/weekPicker'
import { api } from '@/api/index'
import { getUserPoint } from '@/utils/util'
import common from '@/utils/common'
const Times = {
  上午: 'morning',
  下午: 'afternoon',
  晚上: 'evening',
}
export default {
  name: 'submitOnDemandView',
  mixins: [SetPvAndUv],
  components: {
    areaSelect,
    weekPicker,
  },
  data() {
    return {
      loading: true,
      playType: '',
      show: false,
      date: '',
      minDate: new Date(),
      phone: '',
      address: '',
      addressShow: false,
      filmImg: '',
      filmTitle: '',
      filmScore: 4,
      filmPlayNum: 0,
      filmType: '',
      filmLength: 0,
      filmDescription: '',
      filmId: '888',
      onDemand: false,
      mobile: '',
      // 提交表单
      subParam: {
        expectPlayEndTime: '',
        expectPlayStartTime: '',
        expectPlayTime: '',
        expectPlayPlace: '',
        filmId: '',
        remark: '',
        placeId: '', // 地点id
        // 拼团人数
        groupNumber: 0,
        showPointId: '', // 点播地点id
      },
      // 放映点信息
      userPoint: null,
      userInfo: null,
    }
  },
  mounted() {
    this.userInfo = this.$store.state.userInfo
    this.initPage()
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/onDemand') {
      to.meta.keepAlive = true
      next()
    } else {
      next()
    }
  },
  methods: {
    // 初始化页面
    initPage() {
      this.filmId = this.getUrlParam('filmId')
      this.playType = this.getUrlParam('playType')
      this.subParam.filmId = this.filmId
      this.userPoint = getUserPoint()
      this.$nextTick(() => {
        this.getFilmById(this.filmId)
      })
      // 设置选择时间为下下周一
      //minDate 加30天
      this.minDate = this.moment().weekday(7).toDate()
      this.address = this.userPoint.address
      if (common.isMobile(this.userInfo.mobile)){
        this.mobile = this.userInfo.mobile
      }else if(common.isMobile(this.userInfo.username)) {
        this.mobile = this.userInfo.username
      }else {
        this.mobile = ''
      }
      // this.getOnDemandRecord()
    },
    // 根据id返回影片详细信息
    getFilmById(id) {
      api.getFilmAroundDetail({id,showPointId:this.userPoint.id}).then((res) => {
        const {code,result} =res
        if (code!== 200){
          Toast.fail(result.message)
          return
        }
        // console.log("影片详细", result);
        this.filmTitle = result.name
        this.filmImg = result.filmPicture
        this.filmScore = result.score
        this.filmPlayNum = result.clickCount
        this.filmType = result.type
        this.filmLength = result.filmLength
        this.filmDescription = result.synopsis
        this.filmDirector = result.director
        this.filmWriter = result.scriptwriter
        this.filmActor = result.starrings
        this.loading = false
      })
    },
    // 获取URL参数
    getUrlParam(name) {
      return this.$route.query[name] || ''
    },
    closeArea() {
      this.addressShow = false
    },
    // 更改放映点提示
    changePlaceTip() {
      Dialog.confirm({
        title: '提示',
        message: '如需修改放映点，需跳转至影片选择列表重新选择放映点，确定要跳转吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonColor: '#3d8bff'}).then(() => {
        this.$router.replace({
          path: '/onDemand',
        })
      })
    },

    // 格式化日期

    formatDate(date) {
      return `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日`
    },

    // 格式化时间
    onConfirm(date) {
      this.subParam.expectPlayStartTime = date.Date.monday.format('YYYY-MM-DD')
      this.subParam.expectPlayEndTime = date.Date.sunday.format('YYYY-MM-DD')
      this.subParam.expectPlayTime = date.Time
      this.show = false
      this.date = `${date.Date.monday.format(
        'MM月DD日',
      )} - ${date.Date.sunday.format('MM月DD日')} (${date.Time})`
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish(selectedOptions) {
      console.log(selectedOptions)
      this.addressShow = false
      // selectedOptions最后一组
      const { value } = selectedOptions[selectedOptions.length - 1]
      this.subParam.placeId = value
      // this.subParam.groupNumber
      console.log({ selectedOptions })
      api
        .getChildList({ id: selectedOptions[selectedOptions.length - 2].value })
        .then((result) => {
          this.subParam.groupNumber = result.filter(
            (r) => r.id === value,
          )[0].groupNumber
        })
      this.address = selectedOptions.map((item) => item.text).join('')
      this.subParam.expectPlayPlace = this.address
    },
    // 提交点播
    submit() {
      console.log(this.subParam)
      if (this.subParam.expectPlayPlace == '') {
        this.subParam.expectPlayPlace = this.address
      }
      // 验证提交表单
      if (!this.subParam.expectPlayStartTime) {
        Toast.fail('请选择期望放映时间')
        return
      }
      if (!this.subParam.expectPlayEndTime) {
        Toast.fail('请选择期望放映时间')
        return
      }
      if (!this.subParam.expectPlayPlace) {
        Toast.fail('请选择播放地点')
        return
      }
      // 判断手机号如果有则验证
      if (this.mobile) {
        if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
          Toast.fail('请输入正确的手机号')
          return
        }
      }
      const param = {
        ...this.subParam,
        expectPlayPeriod: Times[this.subParam.expectPlayTime],
        phone: this.mobile,
        showPointId: this.userPoint.id,
      }
      // console.log({ param });
      api.submitPersonalClickV3(param).then((res) => {
        const { success, message } = res
        if (success) {
          // Toast('提交成功')
          // setTimeout(() => {
          //   // this.$router.history.go(-2)
          // }, 3000)
          Dialog.alert({
            title: '提交成功',
            message: '感谢您的点播需求，我们将尽快安排放映，请耐心等待。',
            confirmButtonText: '确定',
            callback: () => {
              // this.$router.history.go(-2)
              this.$router.replace({
                path: '/onDemand/success',
                query: {
                  ...this.subParam,
                  showPointName: this.userPoint.name,
                  filmPicture: this.filmImg,
                  filmTitle: this.filmTitle,
                  filmId: this.filmId,
                  showPointId: this.userPoint.id,
                },
              })
            },
          })
        } else {
          Toast.fail(message)
        }
      })
      //执行团体提交
      // if (this.playType === '2') {
      //   api.submitGroupClick(this.subParam).then((res) => {
      //     const { success, message } = res
      //     if (success) {
      //       Toast('提交成功')
      //       setTimeout(() => {
      //         this.$router.history.go(-2)
      //       }, 3000)
    },
    //执行个人点播提交
    // if (this.playType === '1') {
    //   api.submitPersonalClick(this.subParam).then((res) => {
    //     const { success, message } = res
    //     if (success) {
    //       Toast('提交成功')
    //       setTimeout(() => {
    //         this.$router.history.go(-2)
    //       }, 3000)
    //     }
    //   })
    // }
    // //执行团体提交
    // if (this.playType === '2') {
    //   api.submitGroupClick(this.subParam).then((res) => {
    //     const { success, message } = res
    //     if (success) {
    //       Toast('提交成功')
    //       setTimeout(() => {
    //         this.$router.history.go(-2)
    //       }, 3000)
    //     }
    //   })
    // }
    // },
  },
}
</script>

<style lang="less" scoped>
.desc-box {
  font-size: 14px;
  padding: 10px;
  color: darkgray;
}
.bg {
  width: 100%;
  height: 118px;
  //border:1px solid #000;
  overflow-x: hidden;
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
}

.container {
  padding: 0 10px 0 10px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  background-color: #f6f7f8;
  overflow-x: hidden;
  //position: absolute;
}

.Card {
  //position: relative;
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  //width: 90%;
  margin: 0 auto;
  overflow-x: hidden;
  //height: 150px;
  background-image: radial-gradient(
      circle at 40% 0%,
      rgba(228, 221, 255) 0%,
      rgba(255, 255, 255, 0) 30%
    ),
    radial-gradient(
      circle at 60% 0%,
      rgba(213, 219, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 20%
    ),
    radial-gradient(
      circle at 90% 0%,
      rgba(240, 252, 255) 0%,
      rgba(255, 255, 255, 0) 20%
    );
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-image {
    width: 100px;
    padding: 8px 8px 8px 0;

    > img {
      width: 100%;
      vertical-align: middle;
      margin: 0 auto;
      border-radius: 5px;
    }
  }

  &-title {
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
    font-weight: 600;
    color: #363a44;
    line-height: 24px;
    margin-top: 5px;
  }

  &-score {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    font-weight: 500;
    color: #b9bcbf;
    line-height: 18px;
    margin-top: -10px;

    > span {
      width: 30px;
      height: 18px;
      font-size: 14px;
      //font-family: PingFangSC-Medium, PingFang SC,sans-serif;
      font-weight: 500;
      color: #ffb137;
      line-height: 18px;
    }
  }

  &-length {
    margin-top: 20px;
    height: 19px;
    width: 120px;
    border-radius: 2px;
    border: 1px solid #f4e7d4;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c39e67;
    line-height: 19px;

    > span {
      display: inline-block;
      text-align: center;

      &:first-child {
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6c3b1a;
        padding: 0 5px;
        background: rgb(236, 223, 205);
      }

      &:last-child {
        width: 60px;
        //border:1px solid #000;
        padding: 0 5px;
      }
    }
  }

  &-btn {
    width: 32px;
    height: 32px;
    background: rgba(164, 105, 105, 0.001);
    box-shadow: 0 4px 10px 0 rgba(136, 168, 200, 0.2);
    border: 2px solid #fff;
    backdrop-filter: blur(3px);
    position: absolute;
    right: 10px;
    top: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50px;

    &-text {
      position: absolute;
      width: 50px;
      text-align: center;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #363a44;
      line-height: 17px;
      transform: translateY(180%);
    }
  }
}

.box {
  width: 100%;
  min-height: 80px;
}

.playDate,
.playAddress,
.playMobile {
  margin-bottom: 10px;

  > p {
    padding: 10px;
    margin: 0;
    font-size: 14px;
    font-family: 'Microsoft YaHei', MicrosoftYaHeiSemibold, serif;
    color: #333333;
    font-weight: 500;
  }
  & > .required {
    &::before {
      content: '*';
      color: red;
      margin-right: 5px;
    }
  }
  & > .timeDiv,
  .addressDiv,
  .mobileDiv {
    display: flex;
    justify-content: space-between;
    padding: 17px;
    background-color: #fff;
    border: 1px solid #e8e9ec;
    color: #686b73;
  }
  input[type='text'] {
    width: 100%;
    padding: 10px;
    border: 0px solid #000;
    height: 19px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC, serif;
    color: #686b73;
    line-height: 19px;
  }
}

.box-button {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep .van-cell{
  padding: 0;
}
</style>
