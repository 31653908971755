<template>
  <div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-picker
        :title="title"
        :columns="columns"
        show-toolbar
        @cancel="close"
        @confirm="onConfirm"
      >
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    title: {
      type: String,
      default: '选择放映周',
    },
    show: {
      type: Boolean,
      default: false,
    },
    // 最大展示周数
    weekCount: {
      type: Number,
      default: 4,
    },
    //最小开始时间，最小周内任意日期即可
    minDate: {
      type: Date,
      default: () => {
        return new Date()
      },
    },
  },
  data() {
    return {
      weekList: [],
      columns: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      for (let i = 0; i < this.weekCount; i++) {
        const day = this.moment(this.minDate).add(7 * i, 'days')
        // console.log(day.format('yyyy-MM-DD'));
        this.weekList.push(this.getWeekByDataObj(day))
      }
      this.columns[0] = {
        values: this.weekList.map((t) => {
          // console.log(t.monday._i.getDate());
          return `第${t.weekIndex}周  (${
            t.monday._d.getMonth() + 1
          }月${t.monday._d.getDate()}日 - ${
            t.sunday._d.getMonth() !== t.monday._d.getMonth()
              ? t.sunday._d.getMonth() + 1 + '月'
              : ''
          } ${t.sunday._d.getDate()}日)`
          // return `第${t.weekIndex}周  (${t.monday._d.getMonth() + 1}月${t.monday._d.getDate()}日 - ${t.sunday._d.getMonth() + 1}月${t.sunday._d.getDate()}日)`
        }),
        defaultIndex: 0,
      }
      this.columns[1] = {
        values: ['上午', '下午', '晚上'],
        defaultIndex: 1,
      }

      // this.onConfirm(null, 0) // 默认选中第一个
    })
  },
  methods: {
    // 根据时间返回周一、周日、当年第几周
    getWeekByDataObj(dateTime) {
      const weekIndex = this.moment(dateTime).isoWeek() //第几周
      const monday = this.moment(dateTime).day('1') // 周一
      const sunday = this.moment(dateTime).day('7') // 周日
      return {
        monday,
        sunday,
        weekIndex,
      }
    },
    close() {
      this.$emit('close')
    },
    onConfirm(option, index) {
      console.log(option, index)
      const selectOption = {
        Date: this.weekList[index[0]],
        Time: this.columns[1].values[index[1]],
      }
      console.log({ selectOption })
      this.$emit('onConfirm', selectOption)
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-picker-column {
  overflow: visible !important;
}
</style>
